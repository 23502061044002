import "styles/fonts/typography.css"
import "styles/css/reset.css"
import React from "react"

import gsap from "gsap"
// import { ScrollSmoother } from "gsap/ScrollSmoother"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
// import { Observer } from "gsap/Observer"

import Providers from "components/Providers"

// gsap.registerPlugin(ScrollTrigger, ScrollSmoother, Observer)

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

// if not localhost, disable gsap null checks
if (window.location.hostname !== "localhost") {
  gsap.config({
    nullTargetWarn: false,
  })
}
